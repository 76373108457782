import {Box, Flex, Text} from "@chakra-ui/react";
import styles from "../../pages/Users/styles";
import React from "react";

const ToastComponent = ({title, bg, icon}) => (
    <Flex overflow='hidden' position='relative' justifyContent='center' flexDirection='column' borderRadius='4px 0px 0px 4px' color='black' bg={bg} pl={3} width='530px' fontWeight='600' fontSize='14px' height='40px'>
        <Flex alignItems='center'>
            {icon}
            <Text fontFamily={'Titillium Web'} fontWeight='600' ml={2}>{title}</Text>
        </Flex>
        <Box css={styles.toast} position='absolute' left='5px' bottom='0' mt={1} borderRadius='6px' bg={bg === '#2C628D' ? '#FFFFFF' : '#3D3525'} height='3px' width='40px' />
    </Flex>
)

export default ToastComponent