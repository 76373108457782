import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useForm, useFieldArray} from 'react-hook-form'
import {Flex, Text, Box, Tabs, TabList, Tab, TabPanels, TabPanel, Button} from '@chakra-ui/react'
import Navbar from '../../components/Navbar/Navbar'
import Property from './Property'
import Areas from './Areas'
import Files from './Files'
import {useUser, useCreateProject} from '../../core/hooks'
import styles from './styles'
import mainStyles from "../../styles/mainStyles";

const CreateProject = () => {
    const [propertyPriorities, setPropertyPropertyPriorities] = useState({
        price: 0,
        time: 0,
        cost: 0,
        security: 0,
        human: 0,
        other: 0,
    })
    const [changeLightCountEstimate, setChangeLightCountEstimate] = useState('')
    const {formState: {errors}, register, handleSubmit, setValue, control, getValues, watch,} = useForm({
        defaultValues: {
            image: '',
            beFinancing: 'no',
            areas: [],
            application: '',
            requestLightCount: false,
            propertyType: '',
            propertySize: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            laborCost: '',
            costPerKilowattHour: '',
            operationDaysPerYear: '',
            salesTax: '',
            complienceHyperlink: '',
            lightCount: '',
            lightCountEstimate: '',
            interestRate: '',
            depositAmount: '',
            loanPeriod: '',
            notes: '',
            propertyPriorities: '',
            dailyUsage: '',
        },
    })
    const history = useHistory()
    const {user} = useUser()
    const image = watch('image', '')
    const financing = watch('beFinancing', '')
    const allAreas = watch('areas', '')
    const requestLightCount = watch('requestLightCount', false)
    const lightCountEstimate = watch('lightCountEstimate', '')
    const propertySize = watch('propertySize', '')
    const {fields: areas, append, remove} = useFieldArray({control, name: "areas",});
    const {createProject, isLoading} = useCreateProject()
    const [error, setError] = useState('')
    const [companySync, setCompanySync] = useState('')
    const [opDayError, setOpDayError] = useState(false)
    const [interestRate, setInterestRate] = useState(getValues('interestRate'))
    const [salesTax, setSalesTax] = useState(getValues('salesTax'))

    const onSubmit = (data) => {
        if (Number(getValues('operationDaysPerYear')) > 365) {
            setOpDayError(true)
            return setError('Operation Days Per Year cannot exceed 365')
        }
        setOpDayError(false)
        let costPKW
        if (getValues('costPerKilowattHour')[0] === '.') {
            costPKW = '0' + getValues('costPerKilowattHour')
        } else {
            costPKW = getValues('costPerKilowattHour')
        }
        if (error) return
        setError('')
        const input = {
            ...data,
            image,
            financing,
            costPerKilowattHour: costPKW,
            lightCountEstimate,
            areas: data.areas,
            userId: user?.id,
            salesTax: salesTax,
            companySync: companySync,
            interestRate: interestRate,
            companyId: user?.company?.length > 0 ? user?.company[0]?.id : user?.associatedToCompany ? user?.associatedToCompany?.id : '',
            status: 'Light Count',
            propertyPriorities: [JSON.stringify(propertyPriorities)],
            users: [],
        }
        createProject(input,
            {
                onSuccess: () => {
                    history.push('/projects?success=true')
                },
                onError: (e) => {
                    if (e.response.data.errorMessage === 'Financing values are required') {
                        return setError(e.response.data.errorMessage)
                    }
                },
            },
        )
    }

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Navbar minW="1240px"
                    title={(
                        <Flex>
                            <Link as='h2' m={0} to="/projects">Projects&nbsp;</Link>
                            <Text as='h2' m={0}>/ Create Project</Text>
                        </Flex>)}>
                <Button bg="#C2EF62" color="black" mr={8} ml={12} px={8} isLoading={isLoading} w="135px" type="submit"
                        h="40px">
                    <img src="/images/done.png" alt="done"/>
                    <Text fontWeight="700" ml={1} mt="2px">Done</Text>
                </Button>
            </Navbar>
            <Tabs css={mainStyles.container}>
                <TabList mx="-1px" css={mainStyles.tabNames}>
                    <Tab>Property</Tab>
                    <Tab>Luminaires</Tab>
                    <Tab>Uploads</Tab>
                </TabList>
                <TabPanels css={mainStyles.tabPanels}>
                    <TabPanel>
                        <Property
                            edit={false}
                            duplicateProject={() => ''}
                            setInterestRate={setInterestRate}
                            interestRate={interestRate}
                            isLoadingCreateProject={isLoading}
                            propertySize={propertySize}
                            setSalesTax={setSalesTax}
                            salesTax={salesTax}
                            companySync={companySync}
                            setCompanySync={setCompanySync}
                            opDayError={opDayError}
                            getValues={getValues}
                            lightCountEstimate={changeLightCountEstimate}
                            setLightCountEstimate={setChangeLightCountEstimate}
                            requestLightCount={requestLightCount}
                            setValue={setValue}
                            image={image}
                            setError={setError}
                            error={error}
                            setPropertyPriorities={setPropertyPropertyPriorities}
                            propertyPriorities={propertyPriorities}
                            register={register}
                            errors={errors}/>
                    </TabPanel>
                    <TabPanel position="relative">
                        <Areas
                            allAreas={allAreas}
                            error={error}
                            watch={watch}
                            setValue={setValue}
                            getValues={getValues}
                            register={register}
                            remove={remove}
                            areas={areas}
                            append={append}/>
                    </TabPanel>
                    <TabPanel>
                        <Files
                            getValues={getValues}
                            setValue={setValue}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </form>
    )
}

export default CreateProject
