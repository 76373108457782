import React, {useState} from "react";
import {
    Modal,
    ModalBody,
    Flex,
    Button,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Heading
} from "@chakra-ui/react";
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import {InputLabel} from "../../../components/Theme";

const DuplicateProjectModal = ({isOpen, onClose,isLoadingCreateProject, duplicateProject,error}) => {
    const [projectTitle, setProjectTitle] = useState('')

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#8FCF74' fontWeight="700" fontSize="24px" textAlign="center" as='h2' m={0}>
                    Duplicate Project Details
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pb='50px' pl='120px' pr='120px' overflow="scroll" maxH="600px">
                    <Text  mb={10} textAlign='center' fontSize='24px' >Create a new project title to duplicate property and luminaire information:</Text>
                    <InputLabel
                        onChange={(e) => setProjectTitle(e.target.value)}
                        m='auto' fontSize='18px !important' color='#5A5A5ADE' fontFamily='Titillium Web' width='418px'/>
                    {error && (<Heading mb={0} textAlign="center" color="red.100" fontSize="m">{error}</Heading>)}
                    <Flex fontFamily='Titillium Web' width='100%' justifyContent='space-between' mt={10}>
                        <Button color='black' fontSize='18px' bg='#DBDADA' width='254px' height='50px' onClick={onClose}>
                            Nevermind go back
                        </Button>
                        <Button isLoading={isLoadingCreateProject} disabled={!projectTitle} color='black' fontSize='18px' bg={!projectTitle ? '#F0EFEF' : '#8FCF74'} width='254px' height='50px' onClick={() => duplicateProject(projectTitle)}>
                            Yes Please!
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
export default DuplicateProjectModal